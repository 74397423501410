<template>
  <div class="model-container SyntheticTable">
    <div class="SyntheticTable-header">
      <div class="SyntheticTable-header-title">综合数据查询表</div>
      <el-row :gutter="40">
        <el-col :lg="8" :xl="5">
          <label>业务类型</label>
          <el-select v-model="queryParams.tradeMode" placeholder="请选择业务类型" clearable size="small">
            <el-option
              v-for="item in businessOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :lg="8" :xl="5" v-if="['Statistics.Country', 'Statistics.CustomDistrict'].includes(curRoute)">
          <label>币制</label>
          <el-select v-model="queryParams.currency" placeholder="请选择币制" clearable size="small">
            <el-option
              v-for="item in currencyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :lg="8" :xl="5">
          <template v-if="curRoute === 'Statistics.Client'">
            <label>客户名称</label>
            <el-select v-model="queryParams.customerId" filterable placeholder="请选择客户名称" clearable size="small">
              <el-option
                v-for="item in customerList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
          <template v-if="curRoute === 'Statistics.Country'">
            <label>目的国</label>
            <el-select v-model="queryParams.code" filterable placeholder="请选择目的国" clearable size="small">
              <el-option
                v-for="item in countryList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </template>
          <template v-if="curRoute === 'Statistics.CustomDistrict'">
            <label>关区名称</label>
            <el-select v-model="queryParams.code" filterable placeholder="请选择关区名称" clearable size="small">
              <el-option
                v-for="item in portList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </template>
        </el-col>
        <el-col :lg="16" :xl="9">
          <label>业务量统计区间</label>
          <el-date-picker
            v-model="queryParams.date"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            size="small">
          </el-date-picker>
          <el-button type="primary" @click="getData" size="small">查询</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData">
      <template v-for="(colume, index) in columes">
        <el-table-column
          :key="index"
          :prop="colume.prop"
          :label="colume.label">
        </el-table-column>
      </template>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.current"
        :current-size="pagination.size"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SyntheticTable',
    props: {
      columes: {
        type: Array,
        default: () => []
      },
      queryKey: {
        type: String,
        default: 'tradeMode'
      },
      requestUrl: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        tableData: [],
        businessOptions: [
          { label: '9610', value: 9610 },
          { label: '9710', value: 9710 },
          { label: '9810', value: 9810 },
          { label: '1210', value: 1210 },
        ],
        currencyOptions: [
          { label: '美元', value: '美元' },
          { label: '日元', value: '日本元' },
          { label: '人民币', value: '人民币' },
        ],
        queryParams: {
          tradeMode: 9610,
          customerId: '',
          code: '',
          currency: '美元',
          keySearch: '',
          date: ''
        },
        pagination: {
          current: 1,
          size: 10,
          total: 0,
        },
        customerList: [],
        countryList: [],
        portList: [],
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ]),
      curRoute() {
        return this.$route.name; 
      }
    },
    created () {
      this.getClientList();
      this.getCountryList();
      this.getPortList();
    },
    methods: {
      async getData() {
        const { tradeMode, customerId, code, currency, keySearch, date } = this.queryParams;
        const { size, current } = this.pagination;
        const params = {
          startDate: date?.[0],
          endDate: date?.[1],
          tradeMode,
          customerId,
          code,
          currency,
          keySearch,
          size,
          current,
        }
        const res = await this.$request.get(`/data${this.requestUrl}`, { params });
        if(res.data) {
          const { content, totalElements } = res.data;
          let resultData = [];
          if(totalElements) {
            resultData = content;
            this.pagination.total = res.data.totalElements;
          } else {
            resultData = res.data;
          }
          if(['/countryBusinessData/overallQuery', '/portBusinessData/overallQuery'].includes(this.requestUrl)) {// 目的国、申报关区
            this.tableData = resultData.length ? resultData.map(item => {
              return {
                ...item,
                ratio: '',
                tradeMode: tradeMode,
                currencyName: currency === '日本元' ? '日元' : currency,
                ...(item.numList
                  ?
                    item.numList
                    .filter(item => item.tradeMode === tradeMode)
                    .reduce((prev, cur) => {
                      const { tradeMode, num } = cur;
                      prev.num = num;
                      prev[`num${tradeMode}`] = num;
                      return prev;
                    }, {})
                  :
                    {}),
                ...(item.priceList
                ?
                  item.priceList
                    .filter(item => item.tradeMode === tradeMode)
                    .reduce((prev, cur) => {
                    const { tradeMode, totalPrice } = cur;
                    prev.price = (totalPrice / 10000).toFixed(3);
                    prev[`price${tradeMode}`] = (totalPrice / 10000).toFixed(3);
                    return prev;
                  }, {
                    RMB: 0,
                    USD: 0,
                    YEN: 0,
                  })
                : {
                    RMB: 0,
                    USD: 0,
                    YEN: 0
                  })
              }
            }): []
             
          } else {
            this.tableData =  resultData.length ? resultData.map(item => {
              return {
                ...item,
                ...item.priceList
                  .filter(item => item.tradeMode === tradeMode)
                  .reduce((prev, cur) => {
                    const { serviceMode, tradeMode, totalPrice, currency, currencyName, } = cur;
                    prev.businessTypeName = `${tradeMode}${this.serviceModeEnums[serviceMode]}`;
                    const name = currencyName || currency;
                    if(name === '人民币'){
                      prev.RMB = (totalPrice / 10000).toFixed(3);
                    } else if(name === '美元'){
                      prev.USD = (totalPrice / 10000).toFixed(3);
                    } else if(name === '日本元'){
                      prev.YEN = (totalPrice / 10000).toFixed(3);
                    }
                    return prev;
                  }, {
                    RMB: 0,
                    USD: 0,
                    YEN: 0,
                  })
              }
            }) : [];
          }
        }
      },
      handleSizeChange(val) {
        this.pagination.size = val;
        this.$emit('handle-pagination-change', this.pagination);
      },
      handleCurrentChange(val) {
        this.pagination.current = val;
        this.$emit('handle-pagination-change', this.pagination);
      },
      async getClientList() {
        const res = await this.$request.get(`/data/customerBusinessData/list`, { params: {
          current: 1,
          size: 9999,
        } });
        if(res.code === 200) {
          const { content } = res.data;
          this.customerList = content;
        }
      },
      async getCountryList() {
        const res = await this.$request.get(`/data/countryBusinessData/list`, { params: {
          current: 1,
          size: 9999,
        } });
        if(res.code === 200) {
          const { content } = res.data;
          this.countryList = content;
        }
        
      },
      async getPortList() {
        const res = await this.$request.get(`/data/portBusinessData/list`, { params: {
          current: 1,
          size: 9999,
        } });
        if(res.code === 200) {
          const { content } = res.data;
          this.portList = content;
        }
        
      },
    },
  }
</script>

<style lang="scss" scoped>
.SyntheticTable{
  padding: 24px 24px;
  margin-top: 50px;
  background-color: #fff;
  &-header{
    // display: flex;
    // align-items: center;
    margin-bottom: 16px;
    &-title{
      margin-bottom: 16px;
      font-size: 16px;
      color: #525252;
    }
    label{
      margin-right: 12px;
      margin-bottom: 16px;
      color: #525252;
    }
    .el-select,
    .el-date-editor {
      margin-bottom: 16px;
    }
    ::v-deep(.el-button){
      margin-left: 24px;
      span{
        color: #fff;
      }
    }
    // &-search{
    //   display: flex;
    //   align-items: center;
    //   label{
    //     margin: 0 12px 0 50px;
    //     color: #525252;
    //   }
    //   ::v-deep(.el-input){
    //     width: 200px;
    //   }
    //   ::v-deep(.el-button){
    //     margin-left: 24px;
    //     span{
    //       color: #fff;
    //     }
    //   }
    // }
  }
  ::v-deep(.el-table){
    .el-table__header-wrapper{
      .has-gutter{
        tr{
          background-color: rgba(51, 70, 129, 0.05);
          th{
            &.el-table__cell{
              background-color: rgba(51, 70, 129, 0.05);
              .cell{
                color: #525252;
              }
            }
          }
        }
      }
    }
  }
  .pagination-container{
    display: flex;
    justify-content: end;
    margin-top: 16px;
  }
}
</style>
<template>
  <div class="SearchQuery">
    <el-radio-group v-model="dateType">
      <el-radio-button label="month">月度</el-radio-button>
      <el-radio-button label="quarter">季度</el-radio-button>
      <el-radio-button label="year">年度</el-radio-button>
    </el-radio-group>
    <label class="label">请选择：</label>
    <el-select v-model="date" placeholder="请选择" @change="handleQueryDateChange">
      <el-option
        v-for="item in dateList"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <!-- <el-dropdown @command="handleMonthCommand">
      <div class="SearchQuery-btn">
        <div class="SearchQuery-btn-left">
          <p>月度</p>
          <p>{{ curMonth }}</p>
        </div>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <template v-for="option in monthList">
          <el-dropdown-item :key="option.value" :command="option.value">{{ option.label }}</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown @command="handleQuarterCommand">
      <div class="SearchQuery-btn">
        <div class="SearchQuery-btn-left">
          <p>季度</p>
          <p>{{ curQuarter }}</p>
        </div>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <template v-for="option in quarterList">
          <el-dropdown-item :key="option.value" :command="option.value">{{ option.label }}</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown @command="handleYearCommand">
      <div class="SearchQuery-btn">
        <div class="SearchQuery-btn-left">
          <p>年度</p>
          <p>{{ curYear }}</p>
        </div>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <template v-for="option in yearList">
          <el-dropdown-item :key="option.value" :command="option.value">{{ option.label }}</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown> -->
  </div>
</template>

<script>
  import * as dayjs from 'dayjs'
  import * as quarterOfYear from 'dayjs/plugin/quarterOfYear' // 导入插件
  import 'dayjs/locale/zh-cn' // 导入本地化语言
  dayjs.extend(quarterOfYear);
  export default {
    name: 'SearchQuery',
    data() {
      return {
        dateType: '',
        date: '',
        dateList: [],
        curMonth: '',
        monthList: [],
        curQuarter: '',
        quarterList: [],
        curYear: '',
        yearList: [],
      }
    },
    watch: {
      dateType: {
        immediate: true,
        handler(value) {
          switch(value) {
            case 'month':
              this.dateList = this.monthList;
              this.date = this.curMonth;
              break;
            case 'quarter':
              this.dateList = this.quarterList;
              this.date = this.curQuarter;
              break;
            case 'year':
              this.dateList = this.yearList;
              this.date = this.curYear;
              break;
          }
          this.handleQueryDateChange()
        }
      },
    },
    mounted () {
      this.initMonth();
      this.initQuarter();
      this.initYear();
      this.dateType = 'month';
    },
    methods: {
      handleQueryDateChange() {
        let startDate = '';
        let endDate = '';
        let value = ''
        if(this.date) {
          switch(this.dateType) {
            case 'month' :
              startDate = dayjs(this.date).startOf('month').format("YYYY-MM-DD HH:mm:ss");
              endDate = dayjs(this.date).endOf('month').format("YYYY-MM-DD 23:59:59");
              break;
            case 'quarter' :
              value = this.date.split('-')[1];
              startDate = dayjs(this.date).quarter(value).startOf('quarter').format("YYYY-MM-DD HH:mm:ss");
              endDate = dayjs(this.date).quarter(value).endOf('quarter').format("YYYY-MM-DD 23:59:59");
              break;
            case 'year' :
              startDate = dayjs(this.date).startOf('year').format("YYYY-MM-DD HH:mm:ss");
              endDate = dayjs(this.date).endOf('year').format("YYYY-MM-DD 23:59:59");
              break;
          }
          this.$emit('update:queryParams', { timeTag: this.dateType, startDate, endDate });
        }
      },
      // 月度
      handleMonthCommand(command) {
        const [ year, month ] = command.split('-');
        this.curMonth = this.formatDate({ year, month }).value;
      },
      // 季度
      handleQuarterCommand(command) {
        const [ year, quarter ] = command.split('-');
        this.curQuarter = this.formatQuarter({ year, quarter }).value;
      },
      // 年
      handleYearCommand(command) {
        const curYear = command;
        this.curYear = `${curYear}`;
      },
      initMonth() {
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        let monthList = [];
        for(let i = curMonth; i > 0; i--) {
          const { label, value } = this.formatDate({ year: curYear, month: i });
          monthList.push({ label, value })
        }
        const len = monthList.length;
        if( len < 12) {
          for(let j = 12; j > curMonth; j--) {
            const { label, value } = this.formatDate({ year: (curYear- 1), month: j });
            monthList.push({ label, value })
          }
        }
        this.monthList = monthList;
        const [ { value } ] = this.monthList;
        this.curMonth = value;
      },
      formatDate({ year, month }) {
        const curMonth = `${month}`.padStart(2, '0');
        return {
          label: `${year}年${curMonth}月`,
          value: `${year}-${curMonth}`
        }
      },
      initQuarter() {
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        const curQuarter = Math.floor((curMonth % 3 === 0) ? ( curMonth / 3 ) : (curMonth / 3 + 1));
        let quarterList = [];
        for(let i = curQuarter; i > 0; i--) {
          quarterList.push(this.formatQuarter({ year: curYear, quarter: i }));
        }
        for(let i = 4; i > 0; i--) {
          quarterList.push(this.formatQuarter({ year: curYear  -1, quarter: i }));
        }
        const len = quarterList.length;
        if(len < 8) {
          for(let j = 4; j > curQuarter; j--) {
            quarterList.push(this.formatQuarter({ year: curYear - 2, quarter: j }));
          }
        }
        this.quarterList = quarterList;
        const [ { value } ] = this.quarterList;
        this.curQuarter = value;

      },
      formatQuarter({ year, quarter }) {
        return {
          label: `${year}年第${quarter}季度`,
          value: `${year}-${quarter}`
        }
      },
      initYear() {
        const curYear = new Date().getFullYear();
        const yearList = [];
        for(let i = curYear; i > 2019; i--) {
          yearList.push({ label: `${i}年`, value: `${i}` })
        }
        this.yearList = yearList;
        this.curYear = `${curYear}`;
      },
      // 获取月份第一天和最后一天
      getMonthFirstLastDay(date){
        var y = new Date(date).getFullYear(); //获取年份
        var m = new Date(date).getMonth() + 1; //获取月份
        var endDate = new Date(y, m, 0).getDate(); //获取当月最后一日
        var d = '01';
        m = m < 10 ? '0' + m : m; //月份补 0
        endDate = endDate.padStart(0, 2);
        return {
          startDate: [y, m, d].join("-"),
          endDate: [y, m, endDate].join("-"),
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.SearchQuery{
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  box-shadow: 4px 0px 6px 0px rgba(0,0,0,0.05);
  .label{
    margin: 0 10px 0 56px;
  }
  // &-btn{
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 5px 24px;
  //   margin-right: 20px;
  //   background-color: #0F5FD5;
  //   border-radius: 4px;
  //   &-left{
  //     p{
  //       color: #fff;
  //       text-align: center;
  //     }
  //   }
  //   .el-icon-arrow-down{
  //     color: #fff;
  //   }
  // }
}
</style>